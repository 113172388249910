import React from 'react';
import { useSpring, animated} from 'react-spring';

function Counter({ value }) {
    const animation = useSpring({
        number: value,
        from: { number: 0 },
        config: { tension: 280, friction: 60 }
    })

    const formattedNumber = animation.number.to(number => {
        const formatted = Math.floor(number).toLocaleString('pt-BR', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });
    
        return formatted;
      });
    return (
        <animated.span style={{fontSize: 30, fontWeight: 600}} >
            {formattedNumber}
        </animated.span>
    );
}

export default Counter;