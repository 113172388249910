import React from 'react';
import './Depositions.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BrazilMap from '../BrazilMap/BrazilMap';
import imagensPath from '../imagensPath/imagensPath';
import Video from '../Video/Video';
function Depositions(props) {
    
    const idvideo1 = '9CtBnJ0zSwc'

    const videoids = [
        idvideo1,
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        responsive: [
            {
                breakpoint: 1170,
                settings: {
                    slidesToShow: 1,
                    arrows: false
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    arrows: false
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    arrows: false
                },
            },
        ],
    };


    return (
        <div className='container-depositions' id='depositions'>

            <div className='container-quote-map'>
                <div style={{ padding: '20px', color: '#fff', display: 'flex', alignItems: 'center', textAlign: 'center', flexDirection: 'column', margin: '0 auto', justifyContent: 'center' }}>
                <img src={imagensPath.logo} alt='Logo Campossol' className='logo'/>
                    <span style={{ fontSize: '20px', maxWidth: 300, lineHeight: 'normal' }}>
                        Faça um orçamento!
                        <span style={{ fontSize: '38px', fontWeight: 600, color: '#FF6600' }}> Rápido e fácil</span>
                    </span>

                    <div style={{ padding: '20px' }}>
                        <a className='client-button margin-button' href={`https://api.whatsapp.com/send?phone=5588999316262&text=Quero%20realizar%20um%20orçamento%20agora!`} target='_blank' rel='noreferrer'>SOLICITE AGORA</a>
                    </div>
                    <p style={{ maxWidth: '270px', fontSize: '20px', lineHeight: 'normal', fontWeight: 500 }}>
                        Não guarde dúvidas, entre em contato através do
                        <a style={{ color: '#76C119', textDecoration: 'none' }} href='https://api.whatsapp.com/send?phone=5588999316262'> Nosso WhatsApp</a>.
                    </p>
                </div>
                <BrazilMap />
            </div>

            <h2>Depoimentos de Clientes e Parceiros</h2>
            <div className="deposition-carousel-container">
                <Slider {...settings}>
                    {videoids.map((id, index) => (
                        <div key={index} className="deposition-item">
                            <Video width={320} height={180} videoId={id} />
                        </div>
                    ))}
                </Slider>
            </div>

        </div>
    );
}

export default Depositions;