import React from 'react';
import './Contacts.css'
import { FaInstagram, FaWhatsapp, FaFacebookF, FaPhoneAlt } from 'react-icons/fa';
import { MdEmail } from "react-icons/md";

function Contacts(props) {
    return (
        <div className='container-contacts' id='contacts'>
            <div className='content-contacts'>
                <div className='content-social-medias'>
                    {/* <h5>Fale Conosco</h5>
                    <form style={{ display: 'flex', width: '100%', maxWidth: 500, flexDirection: 'column', margin: 'auto' }}>
                        <div className="form-group p-2">
                            <input type="text" className="form-control" id="nome" placeholder="Nome" required />
                        </div>
                        <div className="form-group p-2">
                            <input type="email" className="form-control" id="email" placeholder="E-mail" required />
                        </div>
                        <div className="form-group p-2">
                            <textarea className="form-control" id="mensagem" rows="3" placeholder="Mensagem..." required></textarea>
                        </div>
                        <div className="text-center">
                            <button type="submit" id='sendMessage' className="btn">ENVIAR</button>
                        </div>
                    </form> */}
                    <h5>Acompanhe nossas redes!</h5>
                    <div className='content-icons'>
                        <a href='https://api.whatsapp.com/send?phone=5588999316262' target='_blank' rel="noreferrer"><FaWhatsapp /></a>
                        <a href='https://www.facebook.com/campos.soll' target="_blank" rel="noreferrer"><FaFacebookF /></a>
                        <a href='https://www.instagram.com/campos.soll/' target='_blank' rel="noreferrer"><FaInstagram /></a>
                    </div>
                </div>

                <div className='content-phone-email'>
                    <div style={{margin:'0 auto'}}>
                        <FaPhoneAlt style={{ marginRight: '10px', fontSize: 13 }}
                        />
                        <span style={{ marginRight: '20px', fontSize: 13}}>(88)99835-9507</span>
                    </div>
                    <span style={{ margin: '0 auto', fontSize: 13}}>(88)99931-6262</span>
                    <div>
                        <MdEmail  style={{ marginRight: '10px'}} />
                        <span style={{ margin: '0 auto', fontSize: 13 }}>ccampos.soll@gmail.com</span>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default Contacts;