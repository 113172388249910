import React from 'react';
import BannerSlider from '../BannerSlider/BannerSlider';
import Results from '../Results/Results';
import Map from '../Map/Map';
import SolutionsGrid from '../SolutionsGrid/SolutionsGrid';
import CookieNotice from '../Cookies/Cookies';
import MyNavbar from '../MyNavbar/MyNavbar';
import Portfolio from '../Portfolio/Portfolio';
import Benefits from '../Benefits/Benefits';
import Depositions from '../Depositions/Depositions';
import FooterDev from '../FooterDev/FooterDev';
import Partners from '../Partners/Partners';
import Contacts from '../Contacts/Contacts';

const PageApp = () => {
    return (
        <div>
        <MyNavbar />
        <BannerSlider/>
        <Results/>
        <Map/>
        <SolutionsGrid/>
        <Portfolio/>
        <Benefits/>
        <Depositions/>
        <Partners/>
        <Contacts/>
        <FooterDev/>
        <CookieNotice/>
        {/* <Video videoId={'9CtBnJ0zSwc'} width={350} height={250}/> */}
        </div>
    );
};

export default PageApp;