import React from 'react';
import Counter from '../Counter/Counter';
import '../Results/Results.css'
import imagensPath from '../imagensPath/imagensPath';

function Results() {
    const dataResults = [
        { id: '1', image: imagensPath.obras, valor: 320, text: 'Obras Realizadas' },
        { id: '2', image: imagensPath.potencia, valor: 4068, text: 'KWP de Potência Já Instalada' },
        { id: '3', image: imagensPath.modulos, valor: 10540, text: 'Módulos Já Instalados' },
        // { id: '4', image: imagensPath.economia, valor: 253432, text: 'Reais de Economia Por Mês' },
        // { id: '5', image: imagensPath.co2, valor: 478, text: 'Toneladas de CO² Não Emitidas' },
        // { id: '6', image: imagensPath.arvores, valor: 16492, text: 'Árvores Salvas Por Mês' }
    ];

    const dataResults2 = [
        // { id: '1', image: imagensPath.obras, valor: 320, text: 'Obras Realizadas' },
        // { id: '2', image: imagensPath.potencia, valor: 4068, text: 'KWP de Potência Já Instalada' },
        // { id: '3', image: imagensPath.modulos, valor: 10540, text: 'Módulos Já Instalados' },
        { id: '4', image: imagensPath.economia, valor: 253432, text: 'Reais de Economia Por Mês' },
        { id: '5', image: imagensPath.co2, valor: 478, text: 'Toneladas de CO² Não Emitidas' },
        { id: '6', image: imagensPath.arvores, valor: 16492, text: 'Árvores Salvas Por Mês' }
    ];

    return (

        <div className='about' id='about'>
            <img className='quotation-marks' alt='quotation-marks' src={imagensPath.aspas} />
            <blockquote className='quote-container'>
                Queremos proporcionar economia e liberdade aos nossos clientes,
                através de sua geração própria de energia, com os melhores
                equipamentos do mercado e com orçamentos acessíveis, contribuindo
                para a sustentabilidade do nosso planeta.
                <p className="quote">
                    <span className="author">Damião Ferreira</span> - <span className="profession">CEO da Campossol</span>
                </p>
            </blockquote>

            <h2>Resultados e Realizações Campossol</h2>

            <section className='results pt-4 pb-5'>

                <div className='result'>
                    {dataResults.map((item) => (
                        <div className='data' key={item.id}>
                            <img src={item.image} alt={item.text}  title={item.text} />
                            <Counter value={item.valor} />
                            <span>{item.text}</span>
                        </div>
                    ))}
                </div>

                <div className='result'>
                    {dataResults2.map((item) => (
                        <div className='data' key={item.id}>
                            <img src={item.image} alt={item.text}  title={item.text} />
                            <Counter value={item.valor} />
                            <span>{item.text}</span>
                        </div>
                    ))}
                </div>
            </section>

        </div>

    );
}

export default Results;