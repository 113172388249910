import constructor from '../../assets/constructor.svg'
import solarenergy from '../../assets/solar-energy.svg'
import solarpanel from '../../assets/solar-panel.svg'
import ecolamp from '../../assets/eco-lamp.svg'
import co2 from '../../assets/co2.svg'
import hope from '../../assets/hope.svg'

const imagensPath = {
    logo: require('../../assets/logo.png'),
    logoCarroussel: require('../../assets/logo-carrousel.png'),
    aspas: "https://keenitsolutions.com/products/wordpress/braintech/wp-content/uploads/2020/11/quote-white.png",
    bannerslider1: 'https://images.pexels.com/photos/4320449/pexels-photo-4320449.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    bannerslider2: 'https://images.pexels.com/photos/6961120/pexels-photo-6961120.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    bannerslider3: 'https://images.pexels.com/photos/19205947/pexels-photo-19205947/free-photo-of-solar-panels-on-a-desert.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    bannerslider4: 'https://images.pexels.com/photos/159397/solar-panel-array-power-sun-electricity-159397.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    painelSolar: require('../../assets/painelSolar.png'),
    operarios: "https://images.pexels.com/photos/8853536/pexels-photo-8853536.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",

    //SVGS
    obras: constructor,
    potencia: solarenergy,
    modulos: solarpanel,
    economia: ecolamp,
    co2: co2,
    arvores: hope

    //PNGS
    // obras: require('../../assets/obras.png'),
    // potencia: require('../../assets/potencia.png'),
    // modulos: require('../../assets/modulos.png'),
    // economia: require('../../assets/economia.png'),
    // co2: require('../../assets/co2.png'),
    // arvores: require('../../assets/arvores.png'),
}
export default imagensPath;