import React from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './Partners.css'

function Partners(props) {
    const image1 = require('../../assets/logo-partner.png')
    const image2 = require('../../assets/logo-portfolio-2.png')
    const image3 = require('../../assets/logo-partner.png')
    const image4 = require('../../assets/logo-portfolio-2.png')
    const image5 = require('../../assets/logo-partner.png')

    const logos = [
        image1,
        image2,
        image3,
        image4,
        image5
    ];
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        responsive: [
            {
                breakpoint: 1170,
                settings: {
                    slidesToShow: 3,
                    arrows: false
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    arrows: false
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    arrows: false
                },
            },
        ],
    };

    return (
        <div className='container-partners' id='partners'>
            <div className='content-partners'>
            <h2>Parceiros</h2>

            <div className="logo-carousel-container">
                <Slider {...settings} className='carousel-partners'>
                    {logos.map((logo, index) => (
                        <div key={index} className="logo-item">
                            <img src={logo} width={150} alt={`Logo ${index + 1}`} />
                        </div>
                    ))}
                </Slider>
            </div>
            </div>

        </div>
    );
}

export default Partners;