import React, { useState } from 'react';
import './SolutionsGrid.css';
import Carousel from 'better-react-carousel';
import "react-multi-carousel/lib/styles.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'

function SolutionsGrid() {
    // const slides = [...Array(8).keys()];

    const [selectedCategory, setSelectedCategory] = useState('all');
    const [carouselValue, setCarouselValue] = useState(0);


    const image1 = 'https://lh3.googleusercontent.com/p/AF1QipPfd-e7dZKYbJEfsaEeV_yNst7AHqwHd66koPsk=s680-w680-h510'
    const image2 = 'https://lh3.googleusercontent.com/p/AF1QipPVwUqQc2pNRpTsEnHym9mB9ivC-mYTJBNp7-qa=s680-w680-h510'
    const image3 = 'https://lh3.googleusercontent.com/p/AF1QipNrIO9pTDgfviL12WH9D1_0LrQUNk3Xhq5SKYbk=s680-w680-h510'
    const image4 = 'https://i.pinimg.com/736x/fc/da/4b/fcda4b9030492ae02176549b4884ab5c.jpg'
    const image5 = 'https://img.freepik.com/vetores-premium/edificio-do-hospital-para-ilustracao-vetorial-de-fundo-de-desenhos-animados-de-saude-com-ambulancia-medico-paciente-enfermeiras-e-exterior-da-clinica-medica_2175-1515.jpg'
    const image6 = 'https://lh3.googleusercontent.com/p/AF1QipPfd-e7dZKYbJEfsaEeV_yNst7AHqwHd66koPsk=s680-w680-h510'
    const image7 = 'https://lh3.googleusercontent.com/p/AF1QipPfd-e7dZKYbJEfsaEeV_yNst7AHqwHd66koPsk=s680-w680-h510'
    const image8 = 'https://lh3.googleusercontent.com/p/AF1QipPfd-e7dZKYbJEfsaEeV_yNst7AHqwHd66koPsk=s680-w680-h510'


    const images = [
        { id: '1', image: image1, category: 'residenciais', name: 'Restaurante o Kantão', local: 'Campos Sales/CE' },
        { id: '2', image: image2, category: 'comerciais', name: 'Academia MJ Fitness', local: 'Campos Sales/CE' },
        { id: '3', image: image3, category: 'industriais', name: 'IC Fardamentos', local: 'Campos Sales/CE' },
        { id: '4', image: image4, category: 'comerciais', name: 'Mercadinho do Vavá', local: 'Campos Sales/CE' },
        { id: '5', image: image5, category: 'comerciais', name: 'Clínica', local: 'Araripe/CE' },
        { id: '6', image: image6, category: 'residenciais', name: 'Restaurante o Kantão', local: 'Campos Sales/CE' },
        { id: '7', image: image7, category: 'residenciais', name: 'Restaurante o Kantão', local: 'Campos Sales/CE' },
        { id: '8', image: image8, category: 'residenciais', name: 'Restaurante o Kantão', local: 'Campos Sales/CE' },
    ];

    const filteredImages = selectedCategory === 'all' ? images : images.filter(image => image.category === selectedCategory);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setCarouselValue(0);
    };

    return (
        <div className='solutionsGrid' id='solutions'>
            <div className='content-solutions'>
                <h3>Soluções Personalizadas Desenvolvidas</h3>
                <div className='solutions-btn-grid'>
                    <button className={selectedCategory === 'all' ? 'btn-style active' : 'btn-style'} onClick={() => handleCategoryClick('all')}>Todos</button>
                    <button className={selectedCategory === 'residenciais' ? 'btn-style active' : 'btn-style'} onClick={() => handleCategoryClick('residenciais')}>Residenciais</button>
                    <button className={selectedCategory === 'comerciais' ? 'btn-style active' : 'btn-style'} onClick={() => handleCategoryClick('comerciais')}>Comerciais</button>
                    <button className={selectedCategory === 'industriais' ? 'btn-style active' : 'btn-style'} onClick={() => handleCategoryClick('industriais')}>Industriais</button>
                    <button className={selectedCategory === 'rurais' ? 'btn-style active' : 'btn-style'} onClick={() => handleCategoryClick('rurais')}>Rurais</button>
                </div>

                <div className='carroussel-container'>
                    {filteredImages.length > 0 ? (
                        <Carousel value={carouselValue} cols={4} rows={2} hideArrow loop>
                            {filteredImages.map((image, index) => (
                                <Carousel.Item key={image.id}>
                                    <LazyLoadImage
                                        className="image-solution"
                                        width={'100%'}
                                        height={"90%"}
                                        src={image.image}
                                        alt={image.name}
                                        effect='blur'
                                    />
                                    <div className='d-flex flex-column p-2' style={{ margin: "0 auto" }}>
                                        <span className='solution-title'>{image.name.toUpperCase()}</span>
                                        <span className='solution-local'>{image.local}</span>
                                    </div>
                                </Carousel.Item>

                            ))}

                        </Carousel>
                    ) : (
                        <div style={{ marginTop: '100px', marginBottom: "100px" }}>
                            <p style={{ fontWeight: 500, padding: "20px", fontSize: "20px" }}>Nenhuma imagem disponível para a categoria selecionada.</p>
                        </div>
                    )}
                </div>

            </div>
        </div>)

}

export default SolutionsGrid;
