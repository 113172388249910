import React from 'react';
import './Benefits.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'
import imagensPath from '../imagensPath/imagensPath';
import List from '../List/List';

function Benefits() {

    return (
        <div className='benefits' id='benefits'>
            <div className='content-benefits pb-5'>
                <div className='content-work'>
                    <LazyLoadImage
                        src={imagensPath.painelSolar}
                        alt='Sistema Fotovoltaico'
                        effect='blur'
                    />
                    <div>
                        <h3 style={{maxWidth: 380}}>Como funciona um Sistema Fotovoltaico?</h3>
                        <div style={{textAlign:'left', paddingLeft:50, backgroundColor:'#A19E9B', height:'1px', marginLeft:30, width: '50px'}}></div>
                        <List
                            maxWidth={'380px'}
                            textAlign={'left'}
                            bgcolor={'#83C434'}
                            text1={'Os módulos fotovoltaicos recebem a radiação solar;'}
                            text2={'A energia captada é direcionada ao inversor;'}
                            text3={'O inversor a transforma em energia utilizável;'}
                            text4={'A energia não utilizada é enviada para a rede como crédito;'}
                            text5={'Você pode utilizar o crédito em até 5 anos.'}
                        />

                    </div>
                </div>

                <div className='content-work content-work-reverse'>
                    <div >
                        <h3 style={{maxWidth: 400}}>Principais Benefícios Dos Sistemas Fotovoltaicos</h3>
                        <div style={{textAlign:'left', paddingLeft:50, backgroundColor:'#A19E9B', height:'1px', marginLeft:30, width: '50px'}}></div>
                        <List
                            maxWidth={'300px'}
                            textAlign={'left'}
                            bgcolor={'#E35E05'}
                            text1={'Redução de custo fixo;'}
                            text2={'Retorno do Investimento;'}
                            text3={'Tecnologia de Longa Vida Útil;'}
                            text4={'Energia Limpa e Renovável;'}
                            text5={'Contribuição para a Sustentabilidade.'}
                        />

                    </div>
                    <LazyLoadImage
                        src={imagensPath.operarios}
                        alt='Operadores de Sistemas Voltaicos'
                        effect='blur'
                    />
                </div>
            </div>

        </div>)

}

export default Benefits;
