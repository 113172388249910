import React from 'react';
import './PageNotFound.css'
import MyNavbarError from '../MyNavbarError/MyNavbarError';

function PageNotFound() {
    return (
        <div>
            <MyNavbarError />
            <div className='notFound'>
                <h3>Página Não Encontrada!</h3>
                <img className='mt-3' width={50} height={50} src="https://cdn-icons-png.flaticon.com/512/1818/1818246.png" alt='Página não encontrada' />
            </div>
        </div>
    );
}

export default PageNotFound;