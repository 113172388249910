import React,{useState, useEffect} from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './Portfolio.css'

function Portfolio(props) {
    const image1 = require('../../assets/logo-portfolio.png')
    const image2 = require('../../assets/logo-portfolio-2.png')
    const image3 = require('../../assets/logo-portfolio.png')
    const image4 = require('../../assets/logo-portfolio-2.png')
    const image5 = require('../../assets/logo-portfolio.png')

    // const data = [
    //     { id: '1', image: image1 },
    //     { id: '2', image: image2 },
    //     { id: '3', image: image3 },
    //     { id: '4', image: image4 },
    //     { id: '5', image: image5 }
    // ]
    const logos = [
        image1,
        image2,
        image3,
        image4,
        image5
    ];
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        responsive: [
            {
                breakpoint: 1170,
                settings: {
                    slidesToShow: 3,
                    arrows: false
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    arrows: false
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    arrows: false
                },
            },
        ],
    };
    // const sejaCliente = () => {
    //     alert('EM BREVE..');
    // }

    const [saudacao, setSaudacao] = useState('');

    useEffect(() => {
        const determinarSaudacao = () => {
            const agora = new Date();
            const hora = agora.getHours();

            if (hora >= 6 && hora < 12) {
                setSaudacao('Bom dia');
            } else if (hora >= 12 && hora < 18) {
                setSaudacao('Boa tarde');
            } else {
                setSaudacao('Boa noite');
            }
        };

        determinarSaudacao();
    }, []); // O segundo parâmetro

    return (
        <div className='container-portfolio' id='portfolio'>
            <h2>Portfólio</h2>

            <div className="logo-carousel-container">
                <Slider {...settings}>
                    {logos.map((logo, index) => (
                        <div key={index} className="logo-item">
                            <img src={logo} width={150} alt={`Logo ${index + 1}`} />
                        </div>
                    ))}
                </Slider>
            </div>

            <div>
                <a className='client-button' href={`https://api.whatsapp.com/send?phone=5588999316262&text=${saudacao}!%20Quero%20ser%20um%20cliente%20Campossol`} target='_blank' rel='noreferrer'>SEJA UM CLIENTE CAMPOSSOL</a>
            </div>

        </div>
    );
}

export default Portfolio;