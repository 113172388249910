import React from 'react';

const Video = ({ videoId, width, height }) => {

  return (
    <div>
      <iframe
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${videoId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        title={`${videoId}`}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Video;
