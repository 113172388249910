import React from 'react';
import './List.css'

function List({ text1, text2, text3, text4, text5, bgcolor, maxWidth, textAlign }) {
    return (
        <div style={{padding:20}}>
            <div className='container-step'>
                <div className='numberPoints' style={{ backgroundColor: bgcolor }}>
                    <span>1</span>
                </div>
                <span style={{ maxWidth: maxWidth, textAlign: textAlign }} className='step'>{text1}</span>
            </div>

            <div className='container-step'>
                <div className='numberPoints' style={{ backgroundColor: bgcolor }}>
                    <span>2</span>
                </div>
                <span style={{ maxWidth: maxWidth, textAlign: textAlign }} className='step'>{text2}</span>
            </div>

            <div className='container-step' >
                <div className='numberPoints' style={{ backgroundColor: bgcolor }}>
                    <span>3</span>
                </div>
                <span style={{ maxWidth: maxWidth, textAlign: textAlign }} className='step'>{text3}</span>
            </div>

            <div className='container-step'>
                <div className='numberPoints' style={{ backgroundColor: bgcolor }}>
                    <span>4</span>
                </div>
                <span style={{ maxWidth: maxWidth, textAlign: textAlign }} className='step'>{text4}</span>
            </div>

            <div className='container-step'>
                <div className='numberPoints' style={{ backgroundColor: bgcolor }}>
                    <span>5</span>
                </div>
                <span style={{ maxWidth: maxWidth, textAlign: textAlign }} className='step'>{text5}</span>
            </div>
        </div>
    );
}

export default List;