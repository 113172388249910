import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { register } from 'swiper/element/bundle'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import imagensPath from '../imagensPath/imagensPath';
import './BannerSlider.css'

import 'react-lazy-load-image-component/src/effects/blur.css'
import 'swiper/css';
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'

register();

function BannerSlider() {

  const data = [
    { id: '1', image: imagensPath.bannerslider1, info: imagensPath.logoCarroussel },
    { id: '2', image: imagensPath.bannerslider2, info: '' },
    { id: '3', image: imagensPath.bannerslider3, info: '' },
    { id: '4', image: imagensPath.bannerslider4, info: '' }
  ]

  return (
    <Swiper
      id='home'
      effect='fade'
      autoplay={{
        delay: 3500,
      }}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      style={{ backgroundColor: "#262626" }}
    >
      {data.map((item) => (
        <SwiperSlide key={item.id}>
          <LazyLoadImage
            src={item.image}
            alt={item.id}
            effect='blur'
            width={'100%'}
            height={'100%'}
            className={"slider-item"}>
          </LazyLoadImage>

          {item.info !== '' &&
            <div className='text-overlay'>
              <img src={item.info} alt='info carrossel' />
            </div>
          }

        </SwiperSlide>
      ))}
    </Swiper>
  );

};

export default BannerSlider;