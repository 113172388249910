import React from 'react';
import CookieConsent from 'react-cookie-consent';

function CookieNotice() {
  const cookiesStyle = {
    zIndex: 1030,
    justifyContent: "center",
    padding: '20px',
    backgroundColor: "#474a51"
  }

  const buttonStyle = {
    borderRadius: '10px'
  }


  return (
    <CookieConsent
      style={cookiesStyle}
      buttonStyle={buttonStyle}
      location="bottom"
      buttonText="Entendi"
      cookieName="cookie-consent"
    >
      Este site utiliza cookies para garantir a melhor experiência de usuário.
    </CookieConsent>
  );
}

export default CookieNotice;
