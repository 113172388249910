import React from 'react';
import './FooterDev.css'

function FooterDev(props) {
    return (
        <div className='footer-dev'>
            <span>Desenvolvedor</span>
        </div>
    );
}

export default FooterDev;