import React, { useState } from 'react';
import { FaInstagram, FaWhatsapp, FaFacebookF } from 'react-icons/fa';
import {
    Collapse,
    NavbarToggler,
    NavbarBrand,
    Nav,
} from 'reactstrap';
import imagensPath from '../imagensPath/imagensPath';
import './MyNavbar.css'

function MyNavbar() {
    const [isOpen, setIsOpen] = useState(false)

    const scrollToDiv = (e, id) => {
        e.preventDefault();
        const div = document.getElementById(id);
        const offset = 100; // Ajuste o deslocamento conforme necessário
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = div.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });

        clickLink();
    };

    const toggleNavbar = () => {
        setIsOpen(!isOpen)
    };

    const clickLink = () => {
        if (isOpen === true) {
            setIsOpen(!isOpen)
        }
    };
    return (
        <div>
            <nav className="navbar fixed-top navbar-expand-lg navbar-dark" id='my-navbar'>
                <div className='container-navbar'>
                    <NavbarBrand href="/#home" onClick={clickLink}>
                        <img src={imagensPath.logo} alt='Logo Campossol' className='nav-logo' />
                    </NavbarBrand>
                    <NavbarToggler onClick={toggleNavbar} />
                    <Collapse isOpen={isOpen} className='navbar-collapse'>
                        <Nav className="navbar-nav ms-auto">

                            <div className='container-button-links'>
                                <div className='container-button-links' style={{ paddingTop: "24px" }}>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/#about" onClick={(e) => scrollToDiv(e, 'about')}>Sobre</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/#solutions" onClick={(e) => scrollToDiv(e, 'solutions')}>Portfólio</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/#benefits" onClick={(e) => scrollToDiv(e, 'benefits')}>Benefícios</a>
                                    </li>
                                </div>

                                <li className="nav-item">
                                    <div className='social-medias'>
                                        <div>
                                            <a href='https://api.whatsapp.com/send?phone=5588999316262' className="icon-contact" target='_blank' rel="noreferrer"><FaWhatsapp /></a>
                                            <a href='https://www.facebook.com/campos.soll' className="icon-contact" target="_blank" rel="noreferrer"><FaFacebookF /></a>
                                            <a href='https://www.instagram.com/campos.soll/' className="icon-contact" target='_blank' rel="noreferrer"><FaInstagram /></a>
                                        </div>
                                        <a className="nav-link" href="/#depositions" onClick={(e) => scrollToDiv(e, 'depositions')} >Depoimentos</a>
                                    </div>
                                </li>
                            </div>

                        </Nav>
                    </Collapse>
                </div>
            </nav>
        </div>
    );
}

export default MyNavbar;