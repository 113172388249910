import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet';
import './Map.css'
import 'leaflet/dist/leaflet.css';

const markerIcon = new L.icon({
    iconUrl: require('../../assets/map-marker.png')
})


function Map() {
    const [map, setMap] = useState(null); // Usado para manter uma referência ao mapa
    const [mapFocused, setMapFocused] = useState(false);
    const mapRef = useRef(null);



    const handleMapFocus = () => {
        setMapFocused(true);
    };

    const handleMapBlur = () => {
        setMapFocused(false);
    };

    const position = [-7.056838, -40.366243]; // Substitua as coordenadas com a localização da empresa

    useEffect(() => {
        if (map) {
        }

    }, [map]);


    return (
        <div id='map'>
        <MapContainer
            center={position}
            zoom={13}
            style={{ height: "200px", width: "100%" }}
            scrollWheelZoom={mapFocused}
            onFocus={handleMapFocus}
            onBlur={handleMapBlur}
            whenCreated={setMap}
            touchZoom={true}
            ref={mapRef}
        >

            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position} icon={markerIcon}>
                <Popup>
                    Campossol energia solar<br />
                    <a
                        href="https://www.google.com/maps/place/Campossol+energia+solar/@-7.0570645,-40.3662518,15z/data=!4m14!1m7!3m6!1s0x79f35a4c4a7bf75:0x301adb0e35162dd1!2sCampossol+energia+solar!8m2!3d-7.0570645!4d-40.3662518!16s%2Fg%2F11q1n_8hrn!3m5!1s0x79f35a4c4a7bf75:0x301adb0e35162dd1!8m2!3d-7.0570645!4d-40.3662518!16s%2Fg%2F11q1n_8hrn?entry=ttu"
                        target='_blank'
                        rel='noreferrer'
                    >Ver localização</a>
                </Popup>
            </Marker>
        </MapContainer>
        </div>
    );
}

export default Map;