import React from 'react';
import { FaInstagram, FaWhatsapp, FaFacebookF } from 'react-icons/fa';
import {
    NavbarBrand,
    Nav,
} from 'reactstrap';
import imagensPath from '../imagensPath/imagensPath';
import './MyNavbarError.css'

function MyNavbarError() {

    return (
        <div>
            <nav className="navbar fixed-top navbar-expand-lg navbar-dark" id='my-navbar'>
                <div className='container-navbar'>
                    <NavbarBrand href="/#home">
                        <img src={imagensPath.logo} alt='Logo Campossol' className='nav-logo' />
                    </NavbarBrand>
                    <Nav className="navbar-nav ms-auto">

                        <div className='container-button-links'>

                            <li className="nav-item">
                                <div className='social-medias'>
                                    <div>
                                        <a href='https://api.whatsapp.com/send?phone=5588999316262' className="icon-contact" target='_blank' rel="noreferrer"><FaWhatsapp /></a>
                                        <a href='https://www.facebook.com/campos.soll' className="icon-contact" target="_blank" rel="noreferrer"><FaFacebookF /></a>
                                        <a href='https://www.instagram.com/campos.soll/' className="icon-contact" target='_blank' rel="noreferrer"><FaInstagram /></a>
                                    </div>
                                </div>
                            </li>
                        </div>

                    </Nav>
                </div>
            </nav>
        </div>
    );
}

export default MyNavbarError;